<template>
  <div id="documentParams">
    <section id="block1">
      <div>
        <p class="mainTitle">Ключевой информационный документ
          об условиях добровольного страхования
        </p>
        <p>подготовлен на основании Правил № 195 комбинированного страхования заемщиков кредитов от несчастных случаев и болезней САО «ВСК», в редакции действующей на дату заключения Договора страхования</p>
      </div>
      <div id="QR">
        <img svg-inline src="@/assets/img/KID_QR.png">
      </div>
    </section>
    <section id="block2">
        <p><b>Страховщик:</b> САО «ВСК», 121552, Москва, ул. Островная, 4, лицензия ЦБ РФ СЛ № 0621 от 11.09.2015 г.</p>
      <div class="table">
        <p class="boldTitle">Раздел I. ЧТО ЗАСТРАХОВАНО?</p>
        <div class="flexedBlock borderTop x2">
          <div class="padd">
            <p class="boldTitle">Дополнительные страховые риски:</p>
            <p>1.	Смерть Застрахованного в результате несчастного случая, происшедшего с Застрахованным в период страхования;</p>
            <p>2. Установление Застрахованному инвалидности I или II группы в связи с причинением вреда здоровью Застрахованного вследствие несчастного случая, происшедшего с Застрахованным в период страхования.</p>
          </div>
          <div class="padd">
            <p>{{KIDdata.Amount}} рублей – страховая премия за дополнительные страховые риски</p>
          </div>
        </div>
        <p class="borderBottom padd">По дополнительным страховым рискам выгодоприобретателем является
          {{KIDdata.Name}}
        </p>
        <p class="boldTitle">Раздел II. ЧТО НЕ ЗАСТРАХОВАНО?</p>
        <div class="borderTop padd">
          <p>
            Для дополнительных рисков случаи отказа в страховой выплате определены Разделом 7 Правил страхования.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="table">
        <p class="boldTitle">Раздел III. КАК ПОЛУЧИТЬ СТРАХОВУЮ ВЫПЛАТУ?</p>
        <div class="borderTop padd">
          <p>
            Для получения страховой выплаты по дополнительным страховым рискам требуется представить следующие документы: перечень документов по дополнительным страховым рискам предусмотрен разделом 6 Правил страхования.
            <br><br>
            Страховая выплата осуществляется в течение сроков, установленных п. 6.14. Правил страхования.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="table">
        <p class="boldTitle">Раздел IV. КАК ВЕРНУТЬ СТРАХОВУЮ ПРЕМИЮ?</p>
        <div class="flexedBlock borderTop x2">
          <div class="borderRight padd">
            <b>Основания для возврата страховой премии</b>
          </div>
          <div class="padd">
            <b>Сумма возврата страховой премии</b>
          </div>
        </div>
        <div class="flexedBlock borderTop borderBottom x2">
          <div class="borderRight padd">
            <p>Отказ от договора добровольного страхования в течение 14 календарных дней со дня его заключения</p>
          </div>
          <div class="padd">
            <p>
              100% страховой премии
            </p>
          </div>
        </div>
        <div class="flexedBlock borderBottom x2">
          <div class="borderRight padd">
            <div class="borderBottom">
              <p>
                Отказ от договора добровольного страхования в случае ненадлежащего информирования об условиях страхования
              </p>
            </div>
            <div>
              <p>
                Полное досрочное погашение кредита (займа) (условие применяется для договора страхования, заключенного с 01.04.2023г.)
              </p>
            </div>
          </div>
          <div class="padd">
            <p>
              100% страховой премии за вычетом части страховой премии, исчисляемой пропорционально времени, в течение которого действовало страхование
            </p>
          </div>
        </div>
        <div class="borderBottom padd" style="text-align: center">
          <b class="boldTitle">В иных случаях страховая премия возврату не подлежит.</b>
        </div>
        <div class="padd">
          <p>
            Отказ от страхования возможен в отношении всех страховых рисков.<br><br>
            Возврат страховой премии осуществляется в течение 7 рабочих дней со дня получения соответствующего заявления.
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="table">
        <p class="padd boldTitle">Раздел V. КАК ПОВЛИЯЕТ ОТКАЗ ОТ СТРАХОВАНИЯ НА КРЕДИТ (ЗАЕМ)?</p>
        <div class="borderTop padd">
          <p>Отказ от дополнительных страховых рисков не влияет на кредит (заем).</p>
        </div>
      </div>
    </section>
    <section>
      <div class="table">
        <p class="boldTitle">Раздел VI. КУДА ОБРАЩАТЬСЯ?</p>
        <div class="borderTop padd">
          <p>
            Отказ от дополнительных страховых рисков не влияет на кредит (заем).
          </p>
        </div>
        <div class="flexedBlock borderTop x2">
          <div class="borderRight">
            <b>Страховщику по адресу: </b>
          </div>
          <div>
            <p class="padd">САО «ВСК», 121552, Москва, ул. Островная, 4</p>
          </div>
        </div>
        <div class="flexedBlock borderTop x2">
          <div class="borderRight">
            <b>Кредитору по адресу: </b>
          </div>
          <div>
            <p>{{KIDdata.Address}}</p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="table">
        <p class="boldTitle">Раздел VII. КАК УРЕГУЛИРОВАТЬ СПОР ДО СУДА?</p>
        <div class="borderTop padd">
          <p>
            1.	Направить страховщику заявление (претензию) в письменной форме.<br>
            2.	Если страховщик не удовлетворил заявление (претензию), при этом размер требований не превышает 500 000,00 рублей, до обращения в суд необходимо обратиться к уполномоченному по правам потребителей финансовых услуг:<br>
            <b>сайт:</b> www.finombudsman.ru;<br>
            <b>адрес:</b> 119017, г. Москва, Старомонетный пер., дом 3.<br>
            Рассмотрение уполномоченным по правам потребителей финансовых услуг обращения потребителя финансовых услуг осуществляется бесплатно.
          </p>
        </div>
      </div>
    </section>



  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: "KID_document",
  data() {
    return {
      KIDdata: {}
    }
  },
  methods: {
    getDataKid() {
      this.$external.universalRequest({
        externalType: 'zita',
        action: 'KIDdata',
        demandID: this.activeDealObj.Demand
      }).then((res)=>{
        console.log(res)
        this.KIDdata = res
      }).catch((res)=>{
        if ( res.errorCode > 0 || res.errorCode !== undefined ) {
          alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'activeDealObj',
    ]),
  },
  watch: {
    activeDealObj(value) {
      console.log(value)
      if (value.Demand > 0) {
        this.getDataKid()
      }
    }
  },
  mounted() {
    if(this.activeDealObj.Demand > 0) {
    this.getDataKid()
    }
  }
}
</script>

<style scoped>
  section { margin-bottom: 1.5vw }
  .padd { padding: 0.5vw }
  #block1 { display: flex; justify-content: space-between }
  #block2>p { margin-bottom: 1.5vw }
  #block1>div>h1 { text-align: center }
  #block1>div>p { text-align: center }
  #documentParams { margin: 0 20vw }
  .table { border: 1px solid black }
  .flexedBlock { display: flex }
  .borderBottom { border-bottom: 1px black solid }
  .borderTop { border-top: 1px black solid}
  .borderRight { border-right: 1px black solid }
  .x2>div { width: 50%; display: flex; flex-direction: column; align-items: center }
  h3 { text-align: center; margin: 0.3vw 0 }
  #QR { border: 1px solid black; padding: 0.3vw 3.5vw; display: flex; align-items: center; margin-left: 1vw }
  .boldTitle { font-size: 15px; font-weight: bold; text-align: center }
  .mainTitle { font-size: 20px; font-weight: bold; text-align: center; margin-bottom: 1vw }
  p { margin-bottom: 0 }
  @media print {
    #QR { margin-left: 1vw }
    img { height: 10vw }
    #block1 { margin-top: 3vw }
    #block1>div { margin-top: 3vw }
    #documentParams { margin: 0 5vw }
    .mainTitle { font-size: 15px; margin-bottom: 0.3vw }
    .boldTitle { font-size: 10px }
    p { font-size: 10px; line-height: 15px }
    b { font-size: 10px; line-height: 15px }
    section { margin-bottom: 0.5vw }
  }
</style>